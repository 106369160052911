.container {
  width: 100%;
}

@media (min-width: 320px) {
  .container {
    max-width: 320px;
  }
}

.block {
  display: block;
}

html, body, #react-app-root {
  min-height: 100vh;
}

/*# sourceMappingURL=index.60d913ea.css.map */
